import React from 'react';
import '../styles/ai.css';
import FadeInSection from './FadeInSection';
import Lottie from 'lottie-react';
import animationData from './Animations.json';
import Animate from './animate';


class AI extends React.Component {
	constructor() {
		super();
		this.state = {
			expanded: true,
			activeKey: '1',
		};
		this.handleSelect = this.handleSelect.bind(this);
	}
	handleSelect(eventKey) {
		this.setState({
			activeKey: eventKey,
		});
	}
	render() {
        const lottieStyle = {
          width: '800px', // Optional: Adjust as needed
          height: '600px', // Optional: Adjust as needed

        };
    
        return (
          <div id="ai">
            <FadeInSection>
            <div className="section-header ">
				      <span className="section-title"><b>/ my interest in AI</b></span>
				    </div>
              <div className="aiContent" paddingRight= "20px">
                <div className="aiContent"> <h3>I read a lot, think a lot and write a lot. Mostly about tech, philosophy, and life.</h3></div>
                <Lottie animationData={animationData} style={lottieStyle} />
             </div>
              <div className="aiDescription">
              
              <h3>Follow me on my journey where I dump all of my thoughts on <a href="https://medium.com/@samiyani142/cracking-the-math-code-can-ai-unravel-the-secrets-of-encryption-if-so-should-it-946d50ce78af"> Medium. </a>.</h3>
              </div>
              <br></br><br></br><br></br><br></br><br></br>
              <div style={{ textAlign: 'center' }}><Animate /></div>
              <div align="center" style={{ color: "#c62493", fontSize: "24px", paddingBottom: "50px" }}>
                That's all about me. <br></br>Let's connect and make something awesome together!
                <br /><br />
                <div style={{ transform: 'rotate(180deg)', textAlign: 'center' }}><Animate /></div>
                <div className="introSubtitle">₊✧ ･ﾟ’★,｡･:*:･ﾟ’☆‧₊˚ ✩˚ʚ₍ᐢ. ‿ .ᐢ₎₊˚✧ ﾟ. ⑅ ♡‧₊˚ ✩ </div>
              
              </div>
              
              </FadeInSection>
            </div>
            
          
        );
      }
    }  
export default AI;